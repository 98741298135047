
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page,
} from "framework7-react";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../store/store";

function ProfileForm() {
    f7ready(() => {
    });

    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    const [name, setName] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [username, setUsername] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("+998")
    const [fileURL, setFileURL] = useState(null)
    const [region, setRegion] = useState("")

    //init inputs
    useEffect(() => {
        setName(user.name)
        setLastname(user.lastname)
        setUsername(user.username)
        setPhoneNumber(user.phoneNumber)
        setFileURL(user.fileURL)
        setRegion(user.region)
    }, [])


    useEffect(() => {
        const handleMainBtn = () => {
            dispatch(setUser({name, fileURL, lastname, username, phoneNumber, region}))
            f7.views.main.router.navigate('/main');
        }


        const handleBackBtn = () => {
            f7.views.main.router.navigate('/main');
        }

        const user = window.Telegram.WebApp.initDataUnsafe.user;


        if (user && username === null) {
            console.log(user)
            let telegramId = user.id;
            setUsername(user.username);
        }


        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = "Сохранить изменения";
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.BackButton.show();

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn)
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn)
        }
    }, [username, name, lastname, phoneNumber, fileURL])


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            setPhoneNumber('+998');
        } else {
            setPhoneNumber(value);
        }
    };
    let handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileURL(URL.createObjectURL(file))
        }
    };

    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [])






    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>Личные данные</BlockTitle>
            <BlockHeader>Введите имя и фамилию</BlockHeader>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Имя"
                />
            </List>
            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Фамилия"
                    clearButton={false}
                />
            </List>


            <List strongIos dividersIos insetIos>
                <ListInput
                    type={"tel"}
                    inputmode={"numeric"}
                    placeholder="Номер телефона"
                    maxlength={13}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            </List>
            <BlockFooter>Укажите свой рабочий номер телефона</BlockFooter>

            <List  strongIos dividersIos insetIos>
                <ListItem>
                    <label className="custom-file-label" htmlFor="file-input4">
                        Выбрать обложку
                    </label>
                    <input
                        id="file-input4"
                        type="file"
                        className="custom-file-input"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </ListItem>
                {fileURL && (<ListItem
                    title={"Изображение 1"}
                    after=""
                >
                    <img
                        slot="media"
                        style={{ borderRadius: '8px' }}
                        src={fileURL}
                        width="50"
                        height="50"
                    />
                </ListItem>)}
            </List>


            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="username"
                />
            </List>
            <BlockFooter>Ваш username в телеграмме</BlockFooter>
            <List strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={"Регион"}
                          smartSelect
                >
                    <select onChange={(e) => setRegion(e.target.value)} name={region}
                    >
                        <option>t("chooseRegion")</option>
                        <option value="Ташкент">t('regions.tashkent')</option>
                        <option value="Ташкентская обл">t('regions.tashkent_obl')</option>
                        <option value="Фергана">t('regions.fergana')</option>
                        <option value="Андижан">t('regions.andijan')</option>
                        <option value="Наманган">t('regions.namangan')</option>
                        <option value="Бухара">t('regions.bukhara')</option>
                        <option value="Навои">t('regions.navoi')</option>
                        <option value="Хорезм">t('regions.khorezm')</option>
                        <option value="Сурхандарья">t('regions.surkhandarya')</option>
                        <option value="Кашкадарья">t('regions.kashkadarya')</option>
                        <option value="Сырдарья">t('regions.syrdarya')</option>
                        <option value="Джиззак">t('regions.jizzakh')</option>
                        <option value="Самарканд">t('regions.samarkand')</option>
                        <option value="Каракалпакстан">t('regions.karakalpakstan')</option>
                    </select>
                </ListItem>
            </List>

            <div onClick={() => f7.views.main.router.navigate("/legalentityform")}>
                <List strongIos dividersIos insetIos>
                    <ListItem style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
                        <label style={{color:  '#007AFF'}}>Стать юридическим лицом</label>
                    </ListItem>
                </List>
            </div>

        </Page>
    );
}

export default ProfileForm;