import React, {useCallback, useEffect, useRef, useState} from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import {fromLatLng, geocode, RequestType, setDefaults, setKey} from "react-geocode";
import {f7, f7ready, Icon, List, ListItem, Page} from 'framework7-react';
import pin from "../assets/icons/pin.svg"
import * as Geocode from "react-geocode";

const containerStyle = {
    width: '100%',
    height: '100%'
};

const initialCenter = {
    lat: 41.311081,
    lng: 69.240562
};

const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
};

setDefaults({
    key: "AIzaSyAZPOo0xE1TZtSeW3L_f3bPDU7NMZTvnPI", // api key here.
    language: "uz",
    region: "uz",
});




function Maps({ onData }) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAZPOo0xE1TZtSeW3L_f3bPDU7NMZTvnPI',

    });



    const mapsRef = useRef(null)
    const mapRef = useRef(null);
    const [center, setCenter] = useState(initialCenter);
    const [address, setAddress] = useState("")

    const handleOnLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const handleCenterChanged = useCallback(() => {
        if (mapRef.current) {
            const newCenter = mapRef.current.getCenter();
            setCenter({
                lat: newCenter.lat(),
                lng: newCenter.lng()
            });
        }
    }, []);


    const formatAddress = (results) => {
        if (results && results.length > 0) {
            const addressComponents = results[0].address_components;
            let street = '', neighborhood = '', city = '', district = '', state = '', country = '';
            for (let component of addressComponents) {
                if (component.types.includes('route')) {
                    street = component.long_name;
                } else if (component.types.includes('sublocality') || component.types.includes('neighborhood')) {
                    neighborhood = component.long_name;
                } else if (component.types.includes('locality')) {
                    city = component.long_name;
                } else if (component.types.includes('administrative_area_level_2')) {
                    district = component.long_name;
                } else if (component.types.includes('country')) {
                    country = component.long_name;
                }
            }

            let formattedAddress = [];
            if (street) formattedAddress.push(street);
            if (neighborhood) formattedAddress.push(neighborhood);
            if (city) formattedAddress.push(city);
            if (district && district !== city) formattedAddress.push(district);
            if (state && state !== city && state !== district) formattedAddress.push(state);
            if (country) formattedAddress.push(country);

            return formattedAddress.join(', ');
        }
        return "Address not found";
    };

    useEffect(() => {
        Geocode.fromLatLng(center.lat, center.lng, "AIzaSyAZPOo0xE1TZtSeW3L_f3bPDU7NMZTvnPI", "uz", {
            result_type: "street_address|route|locality|sublocality|administrative_area_level_1|country",
            location_type: "ROOFTOP|RANGE_INTERPOLATED|GEOMETRIC_CENTER",
        }).then(
            response => {
                const address = formatAddress(response.results)
                setAddress(address)
                onData(address);
            },
            error => {
                console.error(error);
            }
        );
    }, [center])





    const onUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    if (!isLoaded) {

        return <div>Loading...</div>;
    }



    return (
        <Page>
            <div style={{
                position: 'fixed',
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{ flex: 1, position: 'relative' }}>
                    <GoogleMap
                        ref={mapsRef}
                        options={mapOptions}
                        mapContainerStyle={{
                            ...containerStyle,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }}
                        center={initialCenter}
                        zoom={10}
                        onLoad={handleOnLoad}
                        onUnmount={onUnmount}
                        onCenterChanged={handleCenterChanged}
                    >
                        <MarkerF position={center} />
                    </GoogleMap>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 10,
                    inlineSize: "50px",
                    wordWrap: 'break-word' ,
                    overflow: 'hidden',
                    width: "100%",
                    height: '15vh',
                    padding: '-4px',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 10,
                }}>
                    <List mediaList strongIos>
                        <ListItem style={{backgroundColor: 'black',}}>
                            <img
                                slot="media"
                                style={{ borderRadius: '8px'}}
                                src={pin}
                                width="44"
                            />
                            <p style={{ fontSize: 13, color: 'gray'}}>{address}</p>
                        </ListItem>
                    </List>
                </div>
            </div>
        </Page>
    );
}

export default Maps;




