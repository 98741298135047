import './App.css';
import {
    App, f7ready, View
} from "framework7-react";
import Registration from "./Screens/Registration";
import Startup from "./Screens/Startup";
import Main from "./Screens/Main";
import MyCars from "./Screens/MyCars";
import CarForm from "./Screens/advanced car form/CarForm";
import SimpleCarForm from "./Screens/simple car form/SimpleCarForm";
import CarEdit from "./Screens/car edit/CarEdit";
import ProfileEdit from "./Screens/ProfileEdit";
import LegalEntityForm from "./Screens/LegalEntityForm";
import MapScreen from "./Screens/MapScreen";


function MyApp() {
    f7ready(() => {
    });
    const f7params = {
        name: 'My App',
        routes: [
            {
                path: '/',
                component: Startup,
            },
            {
                path: '/register',
                component: Registration,
            },
            {
                path: '/simplecarform',
                component: SimpleCarForm,
            },
            {
                path: '/main',
                component: Main,
            },
            {
                path: '/profileedit',
                component: ProfileEdit,
            },
            {
                path: '/legalentityform',
                component: LegalEntityForm,
            },
            {
                path: '/mycars',
                component: MyCars,
            },
            {
                path: '/carform',
                component: CarForm,
            },
            {
                path: '/caredit',
                component: CarEdit,
            },
            {
                path: '/maps',
                component: MapScreen,
            },
        ],
    };
  return (
      <App theme="ios dark" name="My App" {...f7params}>
          <View main url="/" />
      </App>
  );
}

export default MyApp;
