import {BlockFooter, BlockTitle, f7, f7ready, List, ListInput, ListItem, Page, Toggle} from "framework7-react";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {addCar, removeCar} from "../../store/store";
import black from '../../assets/icons/colors/black.svg'
import white from '../../assets/icons/colors/white.svg'
import dolphin from '../../assets/icons/colors/dolphin.svg'
import gray from '../../assets/icons/colors/gray.svg'

export default function CarEdit(props) {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });


    const [brand, setBrand] = useState({ value: '', text: '' });
    const [carModel, setCarModel] = useState({ value: '', text: '' });
    const [carPosition, setCarPosition] = useState({ value: '', text: '' });
    const [options, setOptions] = useState([]);
    const [userBrand, setUserBrand] = useState("")
    const [color, setColor] = useState("")
    const [year, setYear] = useState('');
    const [description, setDescription] = useState("");
    const [cost, setCost] = useState()
    const [fileURL, setFileURL] = useState();
    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))

    const dispatch = useDispatch();

    const pickerDevice = useRef(null);
    const [years, setYears] = useState(() => {
        const newYears = [];
        for (let i = 2000; i < 2024; i++) {
            newYears.push(i);
        }
        return newYears;
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileURL(URL.createObjectURL(file))
        }
    };


    const handleOptionsSelectChange = (e) => {
        const values = Array.from(e.target.selectedOptions, option => option.value)
        setOptions(values)
    }

    const onPageInit = () => {
        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value)
                        console.log(year)
                    }
                },
            ],

        });
    }

    useEffect(() => {
        if (pickerDevice.current) {
            pickerDevice.current.destroy();
        }

        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value);
                    }
                },
            ],
        });
    }, [brand]);




    function handleMainBtn() {
        dispatch(addCar({color, carModel, cost, description, fileURL, year, brand, carPosition, options},uniqueID ))
        f7.views.main.router.navigate('/mycars');
    }

    function handleBackBtn() {
        f7.views.main.router.navigate('/mycars');
    }
    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.MainButton.text = "Добавить машину"
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [description,color, carModel, year, fileURL, brand, carPosition, cost, options])


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [])




    return (
        <Page onPageInit={onPageInit}>
            <List style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title="Марка"
                          smartSelect
                >
                    <select  onChange={((e) => setBrand({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }))}>
                        <option></option>
                        <option  value="other">Other</option>
                        <option  value="chevrolet">Chevrolet</option>
                        <option  value="daewoo">Daewoo</option>
                    </select>
                </ListItem>
            </List>

            {brand.value === "other" && (
                <div>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Ваша марка"
                        />
                    </List>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Ваша модель"
                        />
                    </List>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Позиция"
                        />
                    </List>
                </div>
            )}

            {brand.value === "daewoo" && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Модель"
                                  smartSelect

                        >
                            <select onChange={((e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="matiz">Matiz</option>
                            </select>
                        </ListItem>
                    </List>

                    <List key={`${carModel.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Позиция"
                                  smartSelect
                        >
                            <select  onChange={((e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="first">Первая</option>
                            </select>
                        </ListItem>
                    </List>
                </div>
            )}
            {brand.value === "chevrolet" && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Модель"
                                  smartSelect
                        >
                            <select onChange={((e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="gentra">Gentra</option>
                            </select>
                        </ListItem>
                    </List>


                    <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Позиция"
                                  smartSelect
                        >
                            <select  onChange={((e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="first">Первая</option>
                                <option value="second">Вторая</option>
                            </select>
                        </ListItem>
                    </List>

                </div>
            )}
            <BlockFooter style={{marginTop: -20}}>Укажите комплектацию вашего автомобиля</BlockFooter>


            <div className="carform-ctn">
                <List strongIos dividersIos insetIos>
                    <ListItem>
                        <label className="custom-file-label" htmlFor="file-input">
                            Выберите фото
                        </label>
                        <input
                            id="file-input"
                            type="file"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </ListItem>


                    {fileURL && (<ListItem
                        title={"Изображение 1"}
                        after=""
                    >
                        <img
                            alt="car_img"
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={fileURL}
                            width="50"
                            height="50"
                        />
                    </ListItem>)}
                </List>
                <List strongIos dividersIos insetIos>
                    <ListInput
                        type="textarea"
                        placeholder="Описание"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxlength={200}
                        minlength={50}
                    />
                </List>
                <BlockFooter>Введите описание вашего автомобиля (50-200 слов)</BlockFooter>
                <List strongIos dividersIos insetIos>
                    <ListItem>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder="Цена"
                                value={cost}
                                onChange={(e) => setCost(e.target.value)}
                            />
                        </div>
                        <div className="item-after">USD</div>
                    </ListItem>
                </List>

                <List strongIos dividersIos insetIos>
                    <ListItem
                        title="Опции"
                        smartSelect
                        smartSelectParams={{
                            openIn: 'popup',
                            popupCloseLinkText:"Готово",
                            inputIconPosition: 'end',
                            on: {
                                opened: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = false;
                                },
                                closed: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = true;
                                },
                            }
                        }}
                    >
                        <select onChange={(e) => handleOptionsSelectChange(e)} name="car" multiple>
                            <option value="white">Гидравлика руля</option>
                            <option value="black">ABS</option>
                            <option value="wetAsphalt">Подушка безопасности</option>
                            <option value="dolphin">Зимний режим</option>
                            <option value="gray">Спортивный режим</option>
                            <option value="silver">Сигнализация</option>
                            <option value="sahara">Автозапуск</option>
                        </select>
                    </ListItem>
                </List>

                <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                    <ListItem smartSelectParams={{
                        openIn: 'popup',
                        inputIconPosition: 'end',
                        cssClass: "strongIos dividersIos insetIos",
                        closeOnSelect: true,
                    }}
                              title="Цвет"
                              smartSelect
                    >
                        <select name="car">
                            <option data-option-image={white} value="white">Белый</option>
                            <option data-option-image={black} value="black">Черный</option>
                            <option data-option-image={black} value="wetAsphalt">Мокрый асфальт</option>
                            <option data-option-image={dolphin} value="dolphin">Дельфин</option>
                            <option data-option-image={gray} value="gray">Серый</option>
                        </select>
                    </ListItem>
                </List>

                <List strongIos dividersIos insetIos>
                    <ListInput
                        type="text"
                        placeholder="Указать год"
                        readonly
                        inputId="demo-picker-device"
                    />
                </List>
                <BlockFooter>Выберите год производства автомобиля</BlockFooter>

                <List strongIos dividersIos insetIos>
                    <ListItem >
                        <span>Возможность торгов</span>
                        <Toggle />
                    </ListItem>
                </List>
                <BlockFooter>Позволяет покупателям договариваться о цене </BlockFooter>

            </div>
        </Page>
    );
}

