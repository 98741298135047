import React, {useEffect} from 'react'
import {
    BlockHeader,
    BlockTitle,
    Button,
    Card,
    CardContent,
    CardHeader, f7, f7ready,
    Link,
    List,
    ListItem,
    Page
} from "framework7-react";
import sellzLogo from '../assets/sellz_logo.jpg'
import {useSelector} from "react-redux";



const MyCars = () => {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const cars = useSelector((state) => state.cars);

    function handleBackBtn() {
        f7.views.main.router.navigate('/main');
    }

    function handleMainBtn () {
        window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        f7.views.main.router.navigate('/carform');
    }

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.MainButton.text = "Добавить машину"

        window.Telegram.WebApp.MainButton.isVisible = false;
        window.Telegram.WebApp.MainButton.isVisible = true;

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });
    }, [window.Telegram.WebApp.MainButton.isVisible])



    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>Мои машины</BlockTitle>
            <List dividersIos mediaList insetIos strongIos>
            { (cars.length === 0) ?  (<div>Пока пусто :(</div>) : cars.map((car, index) => (
                    <ListItem
                        onClick={() => { f7.view.main.router.navigate("/caredit", {props: {car}}); }}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'}}
                        key={index}
                        link="#"
                        title={car?.brand?.value === "other" ? car.userBrand : car.carModel.text}
                        after=""
                        subtitle={`Цена: ${car.cost} сум`}
                        text={car.description}
                    >
                         <span  style={{
                            marginTop: 10,
                            fontSize: 14,
                            display: 'inline-block',
                            backgroundColor: `${car.status?.background}`,
                            borderRadius: '8px',
                            padding: '4px 12px',
                            color: `${car.status?.color}`,
                            fontWeight: 'bold',}}>{car.status?.name}</span>
                        <img
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={car.fileURL || sellzLogo}
                            width="90"
                            height="90"
                        />
                    </ListItem>

            ))}
            </List>
        </Page>)
}

export default MyCars;