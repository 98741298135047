import React, {useEffect, useRef} from 'react'
import {
    BlockHeader,
    BlockTitle,
    Button,
    Card,
    CardContent,
    CardHeader, f7, f7ready,
    Link,
    List,
    ListItem,
    Page
} from "framework7-react";
import '../App.css'
import logo from '../assets/icons/cars.svg'
import profile from '../assets/icons/profile.svg'
import sellz_logo from '../assets/sellz_logo.jpg'
import {useSelector} from "react-redux";



const Main = (props) => {
    f7ready(() => {
    });

    const user = useSelector((state) => state.user);

    const toastCenter = useRef(null);
    const showToastRef = useRef();
    let {showToast} = props;

    showToastRef.current = showToast

    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = false;
        window.Telegram.WebApp.BackButton.hide();
    })

    const showToastCenter = () => {
        // Create toast
        if (!toastCenter.current) {
            toastCenter.current = f7.toast.create({
                text: `
    <div class="custom-toast-content">
        <h1 class="toast-title">Машина добавлена! 🎉</h1>
        Вы можете просмотреть/изменить данные о своей машине в разделе "Мои машины"
    </div>
  `,
                position: 'bottom',
                closeTimeout: 5000,
                cssClass:'custom-toast-class',

        })}
        // Open it
        console.log(toastCenter.current.el);
        toastCenter.current.open();
    };
    useEffect(() => {
        if (showToastRef.current) {
            showToastCenter()
            showToastRef.current = false
        }
    }, [])

    const onPageBeforeOut = () => {
        f7.toast.close();
    };

    const onPageBeforeRemove = () => {
        // Destroy toasts when page removed
        if (toastCenter.current) {
            toastCenter.current.destroy();
        }
    }

            return (
        <Page onPageBeforeRemove={onPageBeforeRemove} onPageBeforeOut={onPageBeforeOut}>
            <div className="cards-ctn" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundImage: `url(${user.fileURL || sellz_logo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
               boxShadow: 'inset 0 -150px 100px -50px rgba(0, 0, 0, 0.87)'
            }}>
                <BlockTitle style={{ marginTop: 126, marginLeft: 20,fontSize: 28,lineHeight: 1.2, fontWeight: 'bold', color: 'white' }}>{user.name}</BlockTitle>
                <span style={{
                    marginTop: -4,
                    marginLeft: 20,
                    fontSize: 14,
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    borderRadius: '8px',
                    padding: '4px 8px',
                    color: 'white',
                    fontWeight: 'bold',
                }}>{user.username}</span>
            </div>


            <div>
            <List strongIos dividersIos insetIos>

                <ListItem onClick={() => f7.views.main.router.navigate('/mycars')}   link="#" title="Мои машины" placeholder="Your name">
                    <div slot={"media"} style={{display: 'flex', justifyContent: 'center', backgroundColor:'#609dda', borderRadius:"6px", height: 30, width: 30}}>
                        <img
                            style={{marginBottom: "2px"}}
                            src={logo}
                            width="26"
                        />
                    </div>
                </ListItem>
                <ListItem onClick={() => f7.views.main.router.navigate('/profileedit')}   link="#" title="Личные данные" placeholder="Your name">
                    <div slot={"media"} style={{display: 'flex', justifyContent: 'center', backgroundColor:'#FF3B30', borderRadius:"6px", height: 30, width: 30}}>
                        <img
                            src={profile}
                            width="23"
                        />
                    </div>
                </ListItem>
            </List>
            </div>
    </Page>)
}

export default Main;