import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page, TextEditor, Toggle,
} from "framework7-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import logo from "../../assets/icons/cars.svg";
import image from "../../assets/img.png";
import store, {addCar} from "../../store/store";
import {useDispatch} from "react-redux";
import camera from "../../assets/icons/camera.svg";
import white from "../../assets/icons/colors/white.svg";
import black from "../../assets/icons/colors/black.svg";
import dolphin from "../../assets/icons/colors/dolphin.svg";
import gray from "../../assets/icons/colors/gray.svg";

function SimpleCarForm() {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });

    const pickerDevice = useRef(null);


    const [brand, setBrand] = useState({ value: '', text: '' });
    const [carModel, setCarModel] = useState({ value: '', text: '' });
    const [carPosition, setCarPosition] = useState({ value: '', text: '' });
    const [userBrand, setUserBrand] = useState("")
    const [color, setColor] = useState("")
    const [description, setDescription] = useState("")
    const [year, setYear] = useState('')
    const [isTrade, setIsTrade] = useState(false)
    const [cost, setCost] = useState("")
    const [run, setRun] = useState("")
    const [fileURL, setFileURL] = useState(null)


    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))
    const [years, setYears] = useState(() => {
        const newYears = [];
        for (let i = 2000; i < 2024; i++) {
            newYears.push(i);
        }
        return newYears;
    });

    const dispatch = useDispatch();



    const onPageInit = () => {
        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device1',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value)
                        console.log(year)
                    }
                },
            ],

        });
    }

    useEffect(() => {
        if (pickerDevice.current) {
            pickerDevice.current.destroy();
        }

        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device1',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value);
                    }
                },
            ],
        });
    }, [brand]);


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [])


    function handleMainBtn() {
        window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        dispatch(addCar({
                carModel,
                cost,
                description,
                fileURL,
                userBrand,
                brand,
                carPosition,
                color,
                year,
                status: {name: "На модерации", background: 'rgba(232,228,157,0.2)', color:'#d2cc0a'}},
            uniqueID))
        f7.views.main.router.navigate('/main', {
            props: {
                uniqueID: uniqueID
            }
        } );
    }

    useEffect(() => {

        function handleBackBtn() {
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            f7.views.main.router.navigate('/register');
        }

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.MainButton.text = "ПРОДОЛЖИТЬ"

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [handleMainBtn])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileURL(URL.createObjectURL(file))
        }
    };



    return (
        <Page onPageInit={onPageInit}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>Добавление машины</BlockTitle>
            <List style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title="Марка"
                          smartSelect
                >
                    <select onChange={((e) => setBrand({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }))}>
                        <option></option>
                        <option value="other">Other</option>
                        <option value="chevrolet">Chevrolet</option>
                        <option value="daewoo">Daewoo</option>
                    </select>
                </ListItem>
            </List>

            {brand.value === "other" && (
                <div>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Ваша марка"
                        />
                    </List>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Ваша модель"
                        />
                    </List>
                    <List strongIos dividersIos insetIos>
                        <ListInput
                            style={{marginTop: -10}}
                            type="text"
                            value={userBrand}
                            onChange={(e) => setUserBrand(e.target.value)}
                            placeholder="Позиция"
                        />
                    </List>
                </div>
            )}

            {brand.value === "daewoo" && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Модель"
                                  smartSelect

                        >
                            <select onChange={((e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="matiz">Matiz</option>
                            </select>
                        </ListItem>
                    </List>

                    <List key={`${carModel.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Позиция"
                                  smartSelect
                        >
                            <select onChange={((e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="first">Первая</option>
                            </select>
                        </ListItem>
                    </List>
                </div>
            )}
            {brand.value === "chevrolet" && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                            /*formatValueText: (values) => {if (carModel.text) {return `${carModel.text}`} else { return `${values[0]}`}}*/
                        }}
                                  title="Модель"
                                  smartSelect
                        >
                            <select onChange={((e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="gentra">Gentra</option>
                            </select>
                        </ListItem>
                    </List>


                    <List key={`${carModel.value}-model-list`} style={{marginTop: -10}} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title="Позиция"
                                  smartSelect
                        >
                            <select onChange={((e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            }))}>
                                <option></option>
                                <option value="first">Первая</option>
                                <option value="second">Вторая</option>
                            </select>
                        </ListItem>
                    </List>

                </div>
            )}
            <BlockFooter style={{marginTop: -20}}>Укажите комплектацию вашего автомобиля</BlockFooter>





            <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title="Цвет"
                          smartSelect
                >
                    <select onChange={(e) => setColor(e.target.value) } name="car">
                        <option data-option-image={white} value="white">Белый</option>
                        <option data-option-image={black} value="black">Черный</option>
                        <option data-option-image={black} value="wetAsphalt">Мокрый асфальт</option>
                        <option data-option-image={dolphin} value="dolphin">Дельфин</option>
                        <option data-option-image={gray} value="gray">Серый</option>
                    </select>
                </ListItem>
            </List>
            <BlockFooter>Цвет вашего автомобиля</BlockFooter>
            <List strongIos dividersIos insetIos>
                <ListInput
                    value={year}
                    type="text"
                    placeholder="Указать год"
                    readonly
                    inputId="demo-picker-device1"
                />
            </List>
            <BlockFooter>Выберите год производства автомобиля</BlockFooter>

            <List strongIos dividersIos insetIos>
                <ListItem>
                    <div className="item-input-wrap">
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder="Пробег"
                            value={run}
                            onChange={(e) => setRun(e.target.value)}
                        />
                    </div>
                    <div className="item-after">KM</div>
                </ListItem>
            </List>
            <List strongIos dividersIos insetIos>
                <ListItem>
                    <div className="item-input-wrap">
                        <input
                            type="text"
                            inputMode="numeric"
                            placeholder="Цена"
                            value={cost}
                            onChange={(e) => setCost(e.target.value)}
                        />
                    </div>
                    <div className="item-after">USD</div>
                </ListItem>
            </List>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="textarea"
                    placeholder="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxlength={200}
                    minlength={50}
                />
            </List>
            <BlockFooter>Введите описание вашего автомобиля (50-200 слов)</BlockFooter>

            <div className="carform-ctn">
                <List strongIos dividersIos insetIos>
                    <ListItem className={"file-input-ctn"}>
                        <img
                            width={25}
                            src={camera}
                        />
                        <label style={{marginLeft: 10}} className="custom-file-label" htmlFor="file-input1">
                            Выберите фото
                        </label>

                        <input
                            id="file-input1"
                            type="file"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </ListItem>


                    {fileURL && (<ListItem
                        title={"Изображение 1"}
                        after=""
                    >
                        <img
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={fileURL}
                            width="50"
                            height="50"
                        />
                    </ListItem>)}
                </List>



                <List strongIos dividersIos insetIos>
                    <ListItem>
                        <span>Возможность торгов</span>
                        <Toggle onToggleChange={(checked) => setIsTrade(checked)}/>
                    </ListItem>
                </List>
                <BlockFooter>Позволяет покупателям договариваться о цене </BlockFooter>
            </div>
        </Page>
    );
}

export default SimpleCarForm;