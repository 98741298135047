import Maps from "../Components/Maps";
import {f7} from "framework7-react";
import {useEffect, useState} from "react";


export default function MapScreen () {
    const [address, setAddress] = useState('')
    console.log(address)

    const handleDataFromChild = (data) => {
        setAddress(data);
    };

    useEffect(() => {
        function handleMainBtn () {
            f7.views.main.router.navigate('/legalentityform', {props: {address}});
        }

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = "Указать эту локацию"
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }
    }, [address])
    return (
        <div>
            <Maps onData={handleDataFromChild}/>
        </div>
    )
}