
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page,
} from "framework7-react";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {setUser} from "../store/store";
import {updatePartner} from "../api/partner";

function Registration() {
    f7ready(() => {
    });

    const dispatch = useDispatch()

    const [name, setName] = useState(null)
    const [username, setUsername] = useState(null)
    const [birthday, setBirthday] = useState('')
    const [phoneNumber, setPhoneNumber] = useState("+998")
    const [region, setRegion] = useState("")


    useEffect(() => {
        const handleMainBtn = () => {
            dispatch(setUser({name, username, birthday, phoneNumber, region, entityType: 'person'}))

            const data = new FormData()
            data.append('registered_name', name)
            data.append('username', username)
            data.append('birthday', birthday)
            data.append('phone_number', phoneNumber)

            // TODO: add region to the form
            data.append('region', `${region}_test`)

            // TODO: add language to the form
            data.append('language', 'ru')


            updatePartner(data).then(res => {
                if (res.status === 200) {
                    f7.views.main.router.navigate('/simplecarform');
                }
            }).catch(err => {
                console.log(err)
            })
            // f7.views.main.router.navigate('/simplecarform');
        }
        const handleBackBtn = () => {
            f7.views.main.router.navigate('/');
        }

        const user = window.Telegram.WebApp.initDataUnsafe.user;
        if (user && username === null) {
            console.log(user)
            let telegramId = user.id;
            setUsername(`t.me/${user.username}`);
        }

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = "Перейти к добавлению машины";
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.BackButton.show();

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });
    }, [username, name, birthday, phoneNumber, region])


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            setPhoneNumber('+998');
        } else {
            setPhoneNumber(value);
        }
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('t.me/')) {
            setUsername('t.me/');
        } else {
            setUsername(value);
        }
    };


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [])






    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>Регистрация</BlockTitle>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Имя"
                />
            </List>
            <BlockFooter>Введите ваше имя</BlockFooter>


            <List strongIos dividersIos insetIos>
                <ListInput
                    type={"tel"}
                    inputmode={"numeric"}
                    placeholder="Номер телефона"
                    maxlength={13}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            </List>
            <BlockFooter>Укажите свой рабочий номер телефона</BlockFooter>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="username"
                />
            </List>
            <BlockFooter>Ваш username в телеграмме</BlockFooter>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="datepicker"
                    placeholder={"Выбрать дату"}
                    readonly
                    /*errorMessage={t("birthday")}*/
                    /*errorMessageForce={birthdayIsEmpty}*/
                    calendarParams={{
                        openIn: 'customModal',
                        header: true,
                        footer: true,
                        yearPickerMin: 1950,
                        yearPickerMax: 2010,
                        locale: 'ru',
                        toolbarCloseText: 'Готово',
                        touchMove: false,
                        on: {
                            open(cal) {
                                cal.setYearMonth(1990, 1, 0);
                                cal.update();
                            },
                            change(calendar, value) {
                                setBirthday(value[0]);
                            }
                        }
                    }}
                />
            </List>
            <BlockFooter>Укажите ваш день рождения</BlockFooter>

            <List strongIos dividersIos insetIos>
            <ListItem smartSelectParams={{
                openIn: 'popup',
                inputIconPosition: 'end',
                cssClass: "strongIos dividersIos insetIos",
                closeOnSelect: true,
            }}
                      title={"Регион"}
                      smartSelect
            >
                <select onChange={(e) => setRegion(e.target.value)}
                >
                    <option>t("chooseRegion")</option>
                    <option value="Ташкент">t('regions.tashkent')</option>
                    <option value="Ташкентская обл">t('regions.tashkent_obl')</option>
                    <option value="Фергана">t('regions.fergana')</option>
                    <option value="Андижан">t('regions.andijan')</option>
                    <option value="Наманган">t('regions.namangan')</option>
                    <option value="Бухара">t('regions.bukhara')</option>
                    <option value="Навои">t('regions.navoi')</option>
                    <option value="Хорезм">t('regions.khorezm')</option>
                    <option value="Сурхандарья">t('regions.surkhandarya')</option>
                    <option value="Кашкадарья">t('regions.kashkadarya')</option>
                    <option value="Сырдарья">t('regions.syrdarya')</option>
                    <option value="Джиззак">t('regions.jizzakh')</option>
                    <option value="Самарканд">t('regions.samarkand')</option>
                    <option value="Каракалпакстан">t('regions.karakalpakstan')</option>
                </select>
            </ListItem>
        </List>

        </Page>
    );
}

export default Registration;