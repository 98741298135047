import axios from 'axios';




export const getPartner = async () => {
    try {
        const res = await axios.get('https://selluzauto.uz/api/partner/')
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const Login = async (data) => {
    try {
        const res = await axios.post('https://selluzauto.uz/api/token/', data)

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updatePartner = async (data) => {
    try {
        const res = await axios.put('https://selluzauto.uz/api/partner/', data)
        return res.data
    } catch (e) {
        console.log(e)
    }
}


