import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Framework7 from 'framework7/lite-bundle';
import  Framework7React  from 'framework7-react';
import 'framework7/css/bundle';
import store from "./store/store";
import {Provider} from "react-redux";
import eruda from "eruda";


Framework7.use(Framework7React);

eruda.init()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
