import '../App.css';
import {
    Button, f7, f7ready, Icon,
    Page,
} from "framework7-react";
import React, {useEffect, useState} from "react";
// import axios from 'axios';

import {getPartner, Login} from "../api/partner";
import axios from "axios";

function Startup() {
    f7ready(() => {});

    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))
    const [token, setToken] = useState(null)
    const [refresh, setRefresh] = useState(null)

    useEffect(() => {

        async function login() {
            try {
                const res = await Login({init_data: window.Telegram.WebApp.initData})
                // console.log(res)
                if (res.status === 'success') {
                    setToken(res.access)
                    setToken(res.refresh)
                    localStorage.setItem('token', res.access);
                    localStorage.setItem('refresh', res.refresh);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`

                }
            } catch (e) {
                console.log(e)
            }
        }

        login().then(() => {
            getPartner().then(res => {
                console.log('etetteeer', res.fully_registered)
                if (res.fully_registered === true) {
                    console.log('fully registered', uniqueID)

                      f7.views.main.router.navigate('/mycars',
                    //       {
                    //     props: {
                    //         uniqueID: uniqueID
                    //     }
                    // }
                    );
                }
                else {
                    f7.views.main.router.navigate('/register');
                }
            }).catch(err => {
                console.log(err)
            })
        })



        const handleMainBtn = () => {
            f7.views.main.router.navigate('/register');
        }
        window.Telegram.WebApp.MainButton.text = "ПРОДОЛЖИТЬ"
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.hide();

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        });
    }, [uniqueID]);

    return (
        <Page>
            <div style={{textAlign: 'center', padding: 20}}>
                <h1>Добро Пожаловать!</h1>
                <p style={{fontSize: '1em'}}>
                    <span style={{color: 'var(--tg-theme-button-color)', fontWeight: 'bold'}}>
                        Selluz Motors
                    </span> - покупайте и продавайте авто быстро и надежно
                </p>
            </div>
            <div className="card-content">
                <div className="list media-list no-safe-areas" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <ul style={{marginTop: -30, padding: 0, listStyle: 'none', width: '100%'}}>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '0.9em'}}>
                                <Icon f7="car_fill" size="35px" style={{color: 'var(--tg-theme-button-color)', }}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">Качество</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates itaque autem qui quaera
                                </div>
                            </div>
                        </li>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '0.9em'}}>
                                <Icon f7="money_dollar_circle_fill" size="35px" style={{color: 'var(--tg-theme-button-color)'}}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">Безопасная оплата</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates itaque autem qui quaera
                                </div>
                            </div>
                        </li>
                        <li className="item-content" style={{display: 'flex', alignItems: 'center', padding: '5px 5px'}}>
                            <div className="item-media" style={{marginLeft: '0.9em'}}>
                                <Icon f7="percent" size="35px" style={{color: 'var(--tg-theme-button-color)'}}></Icon>
                            </div>
                            <div className="item-inner" style={{flex: 1}}>
                                <div className="item-title-row">
                                    <div className="item-title">Низкие цены</div>
                                </div>
                                <div style={{marginTop: 5, fontSize: '0.9em'}}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates itaque autem qui quaera
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Button large style={{width: '80%', maxWidth: '300px', margin: '20px auto'}}>ВЫБРАТЬ ЯЗЫК</Button>
                </div>
            </div>
        </Page>
    );
}

export default Startup;
