import {BlockFooter, BlockHeader, BlockTitle, f7, f7ready, List, ListInput, ListItem, Page} from "framework7-react";
import React, {useEffect, useState} from "react";
import logo from "../assets/icons/cars.svg";
import profile from "../assets/icons/profile.svg";
import camera from "../assets/icons/camera.svg";



export default function LegalEntityForm (props) {
    f7ready(() => {
    });

    const {address} = props.address === undefined ? " " : props
    const [dealerName, setDealerName] = useState("");
    const [fileURL, setFileURL] = useState("")

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileURL(URL.createObjectURL(file))
        }
    };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    const truncatedAddress = address && truncateText(address, 20);

    useEffect(() => {
        function handleMainBtn () {
            f7.views.main.router.navigate('/main');
        }
        function handleBackBtn () {
            f7.view.main.router.navigate('main')
        }
        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn )
        window.Telegram.WebApp.MainButton.isVisible = true
        window.Telegram.WebApp.MainButton.text = "Подать заявку"
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }
    }, [])


    return (
        <Page>
            <BlockTitle style={{
                overflow: 'visible',
                width: '300px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                fontSize: 25, lineHeight: 1.2
            }}>Оформление юридического статуса</BlockTitle>

            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    value={dealerName}
                    onChange={(e) => setDealerName(e.target.value)}
                    placeholder="Название автосалона"
                />
            </List>
            <BlockFooter>Введите название вашего автосалона</BlockFooter>

            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListItem
                    onClick={() => f7.views.main.router.navigate("/maps")}
                    link="#" title={"Локация"}
                    after={truncatedAddress}
                    placeholder="Your name"
                >
                </ListItem>
            </List>
            <BlockFooter>Локация вашего автосалона</BlockFooter>

            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListItem className={"file-input-ctn"}>
                    <img
                        width={25}
                        src={camera}
                    />
                    <label style={{marginLeft: 10}} className="custom-file-label" htmlFor="file-input1">
                        Выберите логотип
                    </label>

                    <input
                        id="file-input2"
                        type="file"
                        className="custom-file-input"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </ListItem>


                {fileURL && (<ListItem
                    after=""
                >
                    <img
                        slot="media"
                        style={{ borderRadius: '8px' }}
                        src={fileURL}
                        width="50"
                        height="50"
                    />
                </ListItem>)}
            </List>
        </Page>
    );
}
